import { type ClassValue } from "clsx";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/components/ui/select";
import { cn } from "~/lib/utils";

const WEGLOT_API_KEY = "wg_edb824980dbbd066358a2c34daaf73b41";

declare global {
  interface Window {
    Weglot: {
      initialized: boolean;
      options: {
        language_from: string;
        languages: { language_to: string }[];
      };
      getCurrentLang: () => string;
      getLanguageName: (lang: string) => string;
      switchTo: (lang: string) => void;
      initialize: (options: {
        api_key: string;
        hide_switcher: boolean;
      }) => void;
      on: (event: string, callback: (e: string) => void) => void;
      off: (event: string, callback: (e: string) => void) => void;
    };
  }
}

interface WeglotDataType {
  currentLanguage: string;
  availableLanguages: string[];
}

export const WeglotAppContext = createContext<WeglotDataType>({
  availableLanguages: [],
  currentLanguage: "ja",
});

export function WeglotProvider({ children }: { children: React.ReactNode }) {
  const [weglotData, setWeglotData] = useState<WeglotDataType>({
    availableLanguages: [],
    currentLanguage: "ja",
  });

  function onInitialized() {
    const currentLanguage = window.Weglot.getCurrentLang();
    const availableLanguages = [
      window.Weglot.options.language_from,
      ...window.Weglot.options.languages.map(
        (language) => language.language_to,
      ),
    ];

    setWeglotData({
      currentLanguage,
      availableLanguages,
    });
  }

  function onLanguageChanged(curr: string) {
    setWeglotData((data) => ({
      ...data,
      currentLanguage: curr,
    }));
  }

  useEffect(() => {
    if (window.Weglot) {
      window.Weglot.on("initialized", onInitialized);
      window.Weglot.on("languageChanged", onLanguageChanged);

      if (!window.Weglot.initialized) {
        window.Weglot.initialize({
          api_key: WEGLOT_API_KEY,
          hide_switcher: true,
        });
      }
    }

    return () => {
      if (window.Weglot) {
        window.Weglot.off("initialized", onInitialized);
        window.Weglot.off("languageChanged", onLanguageChanged);
      }
    };
  }, []);

  const contextValue = useMemo(() => weglotData, [weglotData]);

  return (
    <WeglotAppContext.Provider value={contextValue}>
      {children}
    </WeglotAppContext.Provider>
  );
}

export function useWeglot() {
  return useContext(WeglotAppContext);
}

export function SimpleWeglotSwitcher({
  className,
}: {
  className?: ClassValue;
}) {
  const { currentLanguage, availableLanguages } = useWeglot();

  return (
    <div className={cn("no-translate flex space-x-3", className)}>
      {availableLanguages.map((lang) => (
        <button
          key={lang}
          className={cn(
            "border-b-2 border-transparent px-1 py-px",
            currentLanguage === lang && "border-primary",
          )}
          onClick={() => {
            if (window.Weglot) {
              window.Weglot.switchTo(lang);
            }
          }}
        >
          {window.Weglot.getLanguageName(lang)}
        </button>
      ))}
    </div>
  );
}

export function DropdownWeglotSwitcher({
  className,
}: {
  className?: ClassValue;
}) {
  const { currentLanguage, availableLanguages } = useWeglot();

  return (
    <Select
      defaultValue={currentLanguage}
      onValueChange={(v) => {
        if (window.Weglot) {
          window.Weglot.switchTo(v);
        }
      }}
    >
      <SelectTrigger className={cn("no-translate w-[180px]", className)}>
        <SelectValue />
      </SelectTrigger>
      <SelectContent className="no-translate">
        {availableLanguages.map((lang) => (
          <SelectItem key={lang} value={lang}>
            {window.Weglot.getLanguageName(lang)}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}
